
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
}

html,
body {
  /* max-width: 400px; */
  height: 100vh;
  overflow: hidden;
  color: #fff;
  background-color: #000;
  margin-left: auto;
  margin-right: auto;
  /* font-family: 'Helvetica Neue', sans-serif; */
  font-family: "Roboto", sans-serif;
}



@media only screen and (min-width: 640px) {
  body {
    max-width: 400px;

  }
}



.slider-container {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.slider-children {
  height: 100vh;
  scroll-snap-align: start;
  background: #000;
  position: relative;
  /* border: 1px solid transparent; */
  scroll-snap-stop: always;
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-content {
  padding: 10px;
  position: relative;
  top: 85%;
  color: #fff;
}

.bottom-nav {
  position: fixed;
  right: 0;
  bottom: 0px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  /* background-color: #000; */
  background-image: url('/src/Components/Buttons/UI_Images/bottomNav/bg.png');
  background-size: cover;
  background-position: center;
}

.bottom-nav a {
  color: #fff;
  text-decoration: none;
}

.fa {
  font-size: 20px;
}

.fa-plus {
  color: #000;
  background: #fff;
  padding: 3px 10px;
  border-radius: 10px;
  border: 2px solid #ff5722c4;
}

.animate-dragup {
  animation: shake 1s ease infinite;
}

@keyframes shake {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  
  100% {
    transform: translate(0, -50px) rotate(0deg);
  }
}

/* WalletButton */

/* Hide the horizontal scrollbar */
.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.custom-carousel .slick-slide {
  transform: scale(0.8); /* Shrink the non-center slides */
  transition: transform 0.3s ease-in-out;
  opacity: 1; /* Slightly fade out the non-center slides */
}

.custom-carousel .slick-center {
  transform: scale(1); /* Full size for the center slide */
  opacity: 1; /* Full opacity for the center slide */
}